<template>
	<div id="main-wrapper" class="inner-pages dep-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Deposits and Withdrawals</h2>
				<p>A Variety of Easy and Convenient options, at absolutely no cost from our side</p>
			</div>
		</div>
		<div class="section dep-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn t-center">
					<p>Depositing and withdrawing from your trading account has never been so easy! Invest in your trading account by paying no extra fee. We have various reliable deposit and withdrawal options to make your trading journey smooth and easy.</p>
				</div>
				<div class="listing wow fadeIn">
					<div class="info-holder">
						<h3>0% Service fee</h3>
						<p>We do not charge any fees on either deposits or withdrawals</p>
					</div>
					<div class="info-holder">
						<h3>Transactions Safety</h3>
						<p>The funds are kept in Tier-1 Banks and are segregated from the company’s own funds</p>
					</div>
					<div class="info-holder">
						<h3>Quick Processing</h3>
						<p>Deposits and withdrawals with AAAFx are processed instantly</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>$100</h3>
						<p>Minimum deposit amount</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>10+</h3>
						<p>Global currencies accepted</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>Increased control</h3>
						<p>Mandatory KYC ensures greater security</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section deposits-holder" v-if="showtab == 'withdrawal'">
			<div class="wrapper">
				<div class="title-holder t-center wow fadeIn">
					<h3>WITHDRAWALS</h3>
					<p>Seamless withdrawals make every trader happy, right? That is what we aspire to do for our clients. Here are some quick and easy options to use for the withdrawal of your hard-earned money within 24 hours.</p>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>Steps to withdraw from your AAAFx Live Account: <br>
					1. Log into the Client Portal. <br>
					2. Set up a Bank account at ‘Bank setup’. <br>
					3. Click on “Funds” and then “Withdraw”. <br>
					</p>
				</div>
				<div class="deposit-nav">
					<ul>
						<li> <a v-on:click="showtab = 'deposit'" href="#" >Deposit</a> </li>
						<li class="active"> <a href="#" v-on:click="showtab = 'withdrawal'"  >Withdrawals</a> </li>
					</ul>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Cards</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep1.webp" alt="AAAFx" title="AAAFx" width="105" height="31" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>EUR, USD, CHF, GBP</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Wire</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep2.webp" alt="AAAFx" title="AAAFx" width="94" height="28" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>USD</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<!-- <tr>
							<td><img src="assets/images/dep3.webp" alt="AAAFx" title="AAAFx" width="98" height="25" class="fluid-img" /> </td>
							<td>Europe</td>
							<td>EUR (SEPA Only)</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep4.webp" alt="AAAFx" title="AAAFx" width="43" height="31" class="fluid-img" /> </td>
							<td>South East Asia</td>
							<td>MY, TH, ID , PH, VN</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep5.webp" alt="AAAFx" title="AAAFx" width="57" height="43" class="fluid-img" /> </td>
							<td>Latin America</td>
							<td>BR, CL, CR, EC, SV, MX, PA, PE</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep6.webp" alt="AAAFx" title="AAAFx" width="84" height="23" class="fluid-img" /> </td>
							<td>Mexico</td>
							<td>MXN</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr> -->
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Wire</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<!-- <tr>
							<td><img src="assets/images/e1.webp" alt="AAAFx" title="AAAFx" width="81" height="37" class="fluid-img" /> </td>
							<td>Latin America</td>
							<td>CL, CR, EC, MX, PE</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr> -->
						<!-- <tr>
							<td><img src="assets/images/e2.webp" alt="AAAFx" title="AAAFx" width="129" height="22" class="fluid-img" /> </td>
							<td>East Africa</td>
							<td>TAS, KES, MTN</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>Global</td>
							<td>ETH</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>Global</td>
							<td>BTC</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>Global</td>
							<td>USDT</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr> -->
						<tr>
							<td><img src="assets/images/e4.webp" alt="AAAFx" title="AAAFx" width="59" height="21" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR, USD, JPY, CHF, GBP, AUD</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e5.webp" alt="AAAFx" title="AAAFx" width="154" height="18" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>MY, TH, ID, PH, VN</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>Additional Information: <br><br>
					1.	All withdrawals will be processed via the method which was used to make a deposit in the trading account.
					<br><br>
					2.	No transaction fee is charged by AAAFx either on deposits or on withdrawals.
					<br><br>
					3.	We reimburse the transaction cost incurred by you up to 5% of your deposit amount, into your trading account.
					</p>
				</div>
				<div class="btn-holder t-center wow fadeIn">
					<a :href="siteurl2" target="_blank" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
					<a :href="siteurl2" target="_blank" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
				</div>
			</div>
		</div>
		<div class="section deposits-holder" v-else-if="showtab == 'deposit'">
			<div class="wrapper">
				<div class="title-holder t-center wow fadeIn">
					<h3>DEPOSITS</h3>
					<p>What is more important than adding funds securely to your trading account? Start your trading journey with a minimum deposit of just $10. We offer a wide variety of options that you can use to fund your trading account. </p>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>Steps to make a deposit in your AAAFx Live Account:  <br>
					1. Log into the Client Portal. <br>
					2. Click on “Funds” and then “Deposit”. <br>
					</p>
				</div>
				<div class="deposit-nav">
					<ul>
						<li class="active"> <a v-on:click="showtab = 'deposit'" href="#" >Deposit</a> </li>
						<li > <a href="#" v-on:click="showtab = 'withdrawal'"  >Withdrawals</a> </li>
					</ul>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Cards</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep1.webp" alt="AAAFx" title="AAAFx" width="105" height="31" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>EUR, USD, CHF, GBP</td>
							<td>$10</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<!-- <tr>
							<td><img src="assets/images/e7.webp" alt="AAAFx" title="AAAFx" width="80" height="54" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>CNY</td>
							<td>$10</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr> -->
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bitcoin</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e8.webp" alt="AAAFx" title="AAAFx" width="123" height="26" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>BTC</td>
							<td>$10</td>
							<td>$0</td>
							<td>Upto 30 min*</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Wire</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td @click="showinfo = 'eurobank'"><img src="assets/images/dep2.webp" alt="AAAFx" title="AAAFx" width="94" height="28" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>EUR, USD, JPY, GBP, AUD, CHF, BGN</td>
							<td>$10</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<!-- <tr>
							<td @click="showinfo = 'Swissquote'"><img src="assets/images/e9.webp" alt="AAAFx" title="AAAFx" width="129" height="34" class="fluid-img" /> </td>
							<td>Europe</td>
							<td>EUR, USD, JPY, CHF, GBP, AUD</td>
							<td>$10</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr> -->
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>E-Wallets</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e4.webp" alt="AAAFx" title="AAAFx" width="59" height="21" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR, USD, JPY, CHF, GBP, AUD</td>
							<td>$10</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e5.webp" alt="AAAFx" title="AAAFx" width="154" height="18" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR, USD, JPY, GBP, AUD, CHF</td>
							<td>$10</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<!-- <div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>E-Wallets</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/eu1.webp" alt="AAAFx" title="AAAFx" width="74" height="31" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR</td>
							<td>$10</td>
							<td>$0</td>
							<td>1 business day</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/eu2.webp" alt="AAAFx" title="AAAFx" width="66" height="26" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR</td>
							<td>$10</td>
							<td>$0</td>
							<td>1 business day</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/eu3.webp" alt="AAAFx" title="AAAFx" width="97" height="31" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR</td>
							<td>$10</td>
							<td>$0</td>
							<td>1 business day</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/eu4.webp" alt="AAAFx" title="AAAFx" width="43" height="36" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR</td>
							<td>$10</td>
							<td>$0</td>
							<td>1 business day</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/eu5.webp" alt="AAAFx" title="AAAFx" width="99" height="34" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>PLN</td>
							<td>$10</td>
							<td>$0</td>
							<td>1 business day</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/eu6.webp" alt="AAAFx" title="AAAFx" width="66" height="29" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR</td>
							<td>$10</td>
							<td>$0</td>
							<td>1 business day</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div> -->
				<div class="title-holder t-left">
					<p>*In case of network congestion, Bitcoin transactions may take several hours.
				<br><br>
				**For bank wire deposits, AAAFx will cover only the fees applied by our recipient bank i.e Eurobank and up to the amount of $25. AAAFx is not aware of any other fee that may be applied by the sender or any other intermediary bank and thus these fees will not be covered.</p>
				</div>

				<!-- Modal content -->
				<!---end--->
				<div class="btn-holder t-center wow fadeIn">
					<a :href="siteurl2" target="_blank" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
					<a :href="siteurl2" target="_blank" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
				</div>

			</div>
		</div>
		<div class="modal" :class="[{'show' : showinfo == 'eurobank'}]">
			<div class="modal-content">
				<div class="close" @click="showinfo = ''"><vue-feather type="x"></vue-feather></div>
				<h3>EUROBANK</h3>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">USD</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR9502600590000421200023114</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">EUR</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR3202600590000410200460346</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">GBP</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR9702600590000441200009691</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">JPY</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR4202600590000451200009759</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">CHF</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR0602600590000411200012446</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">AUD</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR6202600590000441200012358</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">BGN</span>
							</td>
							<td>
								<span>Swift Code : ERBKGRAA</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS</span>
							</td>
							<td>
								<span>IBAN: GR5402600590000471200017496</span>
								<span class="CurrName">Address: Akti Kondili 26-28, 18545 Piraeus, GREECE</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="warrning">
						<h5>Warning!</h5>
						<p>Include always your email, full name and trading account number (just email and full name for new accounts) in the outgoing wire details for immediate processing. Otherwise AAAFx may delay your deposit.</p>
				</div>
			</div>
		</div>
		<div class="modal" :class="[{'show' : showinfo == 'Swissquote'}]">
			<div class="modal-content">
				<div class="close" @click="showinfo = ''"><vue-feather type="x"></vue-feather></div>
				<h3>SWISSYUBE BANK SA</h3>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">EUR</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">EUR</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">GBP</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">JPY</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">CHF</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">AUD</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Currency</span>
								<span class="CurrName">BGN</span>
							</td>
							<td>
								<span>Swift Code : SWQBCHZZXXX</span>
								<span class="CurrName">Beneficiary's Name: TRIPLE A EXPERTS INVESTMENT SERVICES SA</span>
							</td>
							<td>
								<span>IBAN: CH0708781000097621100</span>
								<span class="CurrName">Address: Chemin de la Crétaux 33, 1196 Gland, Switzerland</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="warrning">
						<h5>Warning!</h5>
						<p>Include always your email, full name and trading account number (just email and full name for new accounts) in the outgoing wire details for immediate processing. Otherwise AAAFx may delay your deposit.</p>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
export default {
name: "Home",
data(){
return {
	showtab: "deposit",
	siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
    siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
    showinfo : ''
}
},
method:{
},
}
</script>